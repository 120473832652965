import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom"
import Home from './pages/Home';
import Whyus from './pages/Whyus';
import JobRoles from './pages/JobRoles';
import Industries from './pages/Industries';
import GetStarted from './pages/GetStarted';
import Careers from './pages/Careers';
import OnShore from './pages/OnShore';
import OnShoreDetailPage from "./pages/OnShoreDetailPage"
import SkilledProfessionals from "./pages/SkilledProfessionals"
import GetstartedThankyou from './pages/ThankyouPages/getStarted';
import Careers_Thankyou from './pages/ThankyouPages/careers';
import ScheduleCall from './pages/ThankyouPages/scheduleCall';
import ThankYou from './pages/ThankyouPages/thankYou';
import Design_1 from './pages/design1';
import Design_2 from './pages/design2';
import Design_3 from './pages/design3';

import Navbar from './component/Navbar';
import Footer from './component/Footer';

import CookiesBanner from './component/cookiesBanner';

import Accounting from './pages/LandingPages/Accounting';
import CustomerService from './pages/LandingPages/CustomerService';
import HR from './pages/LandingPages/HR';
import MedicalSupport from './pages/LandingPages/MedicalSupport';
import TechSupport from './pages/LandingPages/TechSupport';
import VirtualAssistant from './pages/LandingPages/VirtualAssistant';
import TravelAgent from './pages/LandingPages/TravelAgent';
import DigitalMarketing from './pages/LandingPages/DigitalMarketing';
import SalesTeam from './pages/LandingPages/SalesTeam';

import AnsweringService from './pages/Services/AnsweringService';
import AppointmentSetting from './pages/Services/AppointmentSetting';
import Collections from './pages/Services/Collections';
import InboundAppointmentSetting from './pages/Services/InboundAppointmentSetting';
import LeadGeneration from './pages/Services/LeadGeneration';
import OrderProcessing from './pages/Services/OrderProcessing';
import Outbound from './pages/Services/Outbound';
import SalesOutsourcing from './pages/Services/SalesOutsourcing';
import SurveysMarketResearch from './pages/Services/SurveysMarketResearch';
import Telemarketing from './pages/Services/Telemarketing';
import ThirdPartyVerification from './pages/Services/ThirdPartyVerification';
import VirtualReceptionist from './pages/Services/VirtualReceptionist';

import About from "./pages/AboutUs/About"
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import FAQ from './pages/FAQ';
import Contact from './pages/ContactUs';
import WriteReviews from './pages/Reviews/WriteReviews';
import Blog from "./pages/blog";
import CaseStudy from "./pages/blog/case-studies";
import BlogDetail from "./pages/blog/blog-detail";
// import BlogDetail1 from "./pages/blog/blog-detail1";
// import BlogDetail2 from "./pages/blog/blog-detail2";
// import BlogDetail3 from "./pages/blog/blog-detail3";
// import BlogDetail4 from "./pages/blog/blog-detail4";
// import BlogDetail5 from "./pages/blog/blog-detail5";
// import BlogDetail6 from "./pages/blog/blog-detail6";
// import BlogDetail7 from "./pages/blog/blog-detail7";
// import BlogDetail8 from "./pages/blog/blog-detail8";
// import BlogDetail9 from "./pages/blog/blog-detail9";
// import BlogDetail10 from "./pages/blog/blog-detail10";
// import BlogDetail11 from "./pages/blog/blog-detail11";
// import BlogDetail12 from "./pages/blog/blog-detail12";
// import BlogDetail13 from "./pages/blog/blog-detail13";
// import BlogDetail14 from "./pages/blog/blog-detail14";
// import BlogDetail15 from "./pages/blog/blog-detail15";

import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <div>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/why-us" element={<Whyus />} />
          <Route path="/job-roles" element={<JobRoles />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/on-shore" element={<OnShore />} />

          <Route path="/permanent-placement" element={<OnShoreDetailPage />} />
          <Route path="/contract-talent-solutions" element={<OnShoreDetailPage />} />
          <Route path="/remote-hiring" element={<OnShoreDetailPage />} />
          <Route path="/managed-solutions" element={<OnShoreDetailPage />} />
          <Route path="/healthcare-service" element={<SkilledProfessionals />} />
          <Route path="/fintech-service" element={<SkilledProfessionals />} />
          <Route path="/ecommerce-service" element={<SkilledProfessionals />} />
          <Route path="/retail-service" element={<SkilledProfessionals />} />
          <Route path="/travel-support-service" element={<SkilledProfessionals />} />
          <Route path="/insurance-service" element={<SkilledProfessionals />} />
          <Route path="/real-state-service" element={<SkilledProfessionals />} />
          {/* <Route path="/creative-service" element={<SkilledProfessionals />} />
          <Route path="/tech-support" element={<SkilledProfessionals />} />
          <Route path="/travel-services" element={<SkilledProfessionals />} /> */}
          <Route path="/get-started-Thankyou" element={<GetstartedThankyou />} />
          <Route path="/careers-Thankyou" element={<Careers_Thankyou />} />
          <Route path="/Design-1" element={<Design_1 />} />
          <Route path="/Design-2" element={<Design_2 />} />
          <Route path="/Design-3" element={<Design_3 />} />

          <Route path="/sales-team" element={<SalesTeam />} />
          <Route path="/accounting-team" element={<Accounting />} />
          <Route path="/customer-service-team" element={<CustomerService />} />
          <Route path="/hr-team" element={<HR />} />
          <Route path="/medical-support-team" element={<MedicalSupport />} />
          <Route path="/tech-support-team" element={<TechSupport />} />
          <Route path="/virtual-assistant-team" element={<VirtualAssistant />} />
          <Route path="/travel-agent-team" element={<TravelAgent />} />
          <Route path="/digital-marketing-team" element={<DigitalMarketing />} />
          
          <Route path="/answering-service" element={<AnsweringService />} />
          <Route path="/appointment-setting" element={<AppointmentSetting />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/inbound-appointment-setting" element={<InboundAppointmentSetting />} />
          <Route path="/lead-generation" element={<LeadGeneration />} />
          <Route path="/order-processing" element={<OrderProcessing />} />
          <Route path="/outbound" element={<Outbound />} />
          <Route path="/sales-outsourcing" element={<SalesOutsourcing />} />
          <Route path="/surveys-market-research" element={<SurveysMarketResearch />} />
          <Route path="/telemarketing" element={<Telemarketing />} />
          <Route path="/third-party-verification" element={<ThirdPartyVerification />} />
          <Route path="/virtual-receptionist" element={<VirtualReceptionist />} />

          <Route path="/about-us" element={<About />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsandConditions" element={<TermsConditions />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/write-reviews" element={<WriteReviews />} />
          <Route path="/schedule-call" element={<ScheduleCall />} />
          <Route path="/thankyou-for-booking" element={<ThankYou />} />
          <Route
              path="/blog"
              exact
              name="Blog"
              element={<Blog />}
            />
            <Route
              path="/case-studies"
              exact
              name="case Studies"
              element={<CaseStudy />}
            />
            {/* <Route
              path="/mastering-the-art-of-working-with-multiple-virtual-assistants-avoid-these-common-mistakes"
              exact
              name="BlogDetail1"
              element={<BlogDetail1 />}
            />
            <Route
              path="/why-outsourcing-healthcare-bpo-services-from-staffwiz-can-benefit-your-patients"
              exact
              name="BlogDetail2"
              element={<BlogDetail2 />}
            />
            <Route
              path="/the-advantages-of-hiring-workers-through-staffwiz"
              exact
              name="BlogDetail3"
              element={<BlogDetail3 />}
            />
            <Route
              path="/maximizing-efficiency-and-minimizing-costs-the-advantages-of-insurance-bpo-services"
              exact
              name="BlogDetail4"
              element={<BlogDetail4 />}
            />
            <Route
              path="/streamlining-business-operations-a-comprehensive-guide-for-busy-managers-on-navigating-crm-solutions"
              exact
              name="BlogDetail5"
              element={<BlogDetail5 />}
            />
             <Route
              path="/mitigating-the-impact-of-delays-in-the-recruitment-process-with-strategic-staffing-solutions"
              exact
              name="BlogDetail6"
              element={<BlogDetail6 />}
            />
            <Route
              path="/outsourcing-vs-in-house-telemarketing-which-is-right-for-your-business"
              exact
              name="BlogDetail7"
              element={<BlogDetail7 />}
            />
            <Route
              path="/navigating-the-u-s-labor-shortage-challenges-and-solutions"
              exact
              name="BlogDetail8"
              element={<BlogDetail8 />}
            />
            <Route
              path="/unlocking-growth-effective-telemarketing-solutions-for-small-businesses"
              exact
              name="BlogDetail9"
              element={<BlogDetail9 />}
            />
            <Route
              path="/unleashing-the-world-the-comprehensive-travel-services-of-staffwiz"
              exact
              name="BlogDetail10"
              element={<BlogDetail10 />}
            />
            <Route
              path="/smart-strategies-for-startups-outsourcing-staff-for-cost-effective-operations-in-the-usa"
              exact
              name="BlogDetail11"
              element={<BlogDetail11 />}
            />
            <Route
              path="/navigating-in-house-call-center-challenges-a-guide-to-outsourcing-solutions"
              exact
              name="BlogDetail12"
              element={<BlogDetail12 />}
            />
            <Route
              path="/strategic-networking-for-managers-building-connections-that-count"
              exact
              name="BlogDetail13"
              element={<BlogDetail13 />}
            />
            <Route
              path="/empowering-your-business-with-outsourced-team-development-why-its-the-winning-strategy"
              exact
              name="BlogDetail14"
              element={<BlogDetail14 />}
            />
            <Route
              path="/understanding-americas-labor-shortage-a-cautionary-tale-of-unintended-consequences"
              name="BlogDetail15"
              element={<BlogDetail15 />}
            /> */}
         <Route
            
            name="BlogDetail"
            path="/:name"
            element={<BlogDetail />}
            />
          
          <Route path="/hire-software-developers" element={<Navigate replace to="/tech-support-team" />}/>
          <Route path="/customersupport" element={<Navigate replace to="/customer-service-team" />}/>
          <Route path="/hire-sales-representative" element={<Navigate replace to="/sales-team" />}/>
          <Route path="/hire-customer-service" element={<Navigate replace to="/customer-service-team" />}/>
          <Route path="/WageCalculator" element={<Navigate replace to="/" />}/>
          <Route path="/hire-travel-agent" element={<Navigate replace to="/travel-agent-team" />}/>
          <Route path="/hire-overseas-employees" element={<Navigate replace to="/job-roles" />}/>
          <Route path="/hire-sales-representative" element={<Navigate replace to="/sales-team" />}/>
          <Route path="/aboutus" element={<Navigate replace to="/about-us" />}/>
          <Route path="/outsourced-it-support" element={<Navigate replace to="/tech-support-team" />}/>
          <Route path="/hire-virtual-assistant" element={<Navigate replace to="/virtual-assistant-team" />}/>
          <Route path="/buildteam" element={<Navigate replace to="/job-roles" />}/>

          {/* 404 page */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
       
      <CookiesBanner />
      
        <Footer />
      </BrowserRouter>

    </div>

  );
}

export default App;
