import React, { useState, useEffect } from "react";

const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookiesAccepted");
    if (!isAccepted) {
       setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div style={styles.banner}>
        <p style={styles.text}>
          We use cookies to improve your experience. By using our site, you agree to our use of cookies.{" "}
          <a href="/PrivacyPolicy" style={styles.link}>
            Learn more
          </a>
       
        <button onClick={handleAccept} style={styles.button}>
          Accept
        </button>
        </p>
      </div>
    )
  );
};

const styles = {
  banner: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px",
    textAlign: "center",
    zIndex: 1000,
  },
  text: {
    margin: "0",
  },
  link: {
    color: "#91d11b",
    textDecoration: "underline",
  },
  button: {
    marginLeft: "10px",
    padding: "5px 10px",
    backgroundColor: "#91d11b",
    color: "#000",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  },
};

export default CookiesBanner;
